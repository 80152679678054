<template>
  <v-btn

    x-small
    color="success"
    dark
    @click="activate"
  >
    Activate
  </v-btn>
</template>

<script>
import { ref } from '@vue/composition-api'
import api from '../../api'

export default {
  props: {
    campaign: {
      type: Object,
      default() {
        return {
          CampaignID: '',
          Name: '',
          MediaURL: '',
        }
      },
    },
  },
  methods: {
    activate() {
      const vm = this
      const data = {
        CampaignID: this.campaign.CampaignID,
      }

      function after() {
        vm.campaign.Status = 'active'
      }

      api.activateCampaign(data, after)
    },
  },
  setup() {
    const isDialogVisible = ref(false)

    return { isDialogVisible }
  },
}
</script>
