<template>
  <v-dialog
    v-model="isDialogVisible"
    persistent
    max-width="600px"
  >
    <template #activator="{ on, attrs }">
      <v-btn
        color="primary"
        dark
        dense
        v-bind="attrs"

        x-small
        v-on="on"
      >
        Rename Campaign
      </v-btn>
    </template>

    <v-card>
      <v-card-title>
        <span class="headline">Rename Campaign</span>
      </v-card-title>
      <v-card-text>
        <v-container>
          <v-row>
            <v-col
              cols="12"
              sm="12"
              md="12"
            >
              <v-text-field

                v-model="campaign.Name"
                label="Campaign Name"
                dense
              ></v-text-field>
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          color="error"
          outlined
          @click="isDialogVisible = false"
        >
          Close
        </v-btn>
        <v-btn
          color="success"
          @click="isDialogVisible = false; rename()"
        >
          Save
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { ref } from '@vue/composition-api'
import api from '../../api'

export default {
  props: {
    campaign: {
      type: Object,
      default() {
        return {
          CampaignID: '',
          Name: '',
          MediaURL: '',
          Tag: ['home', 'chapter'],
        }
      },
    },
  },
  methods: {
    rename() {
      const vm = this
      api.renameCampaign(vm.campaign.CampaignID, vm.campaign.Name, () => {})
    },
  },
  setup() {
    const isDialogVisible = ref(false)

    return { isDialogVisible }
  },
}
</script>
