<template>
  <v-card>
    <v-card-title class="align-start">
      <span class="font-weight-semibold">{{ campaign.Name }}</span>
      <v-spacer></v-spacer>
      <v-btn
        icon
        small
        class="me-n3 mt-n2"
      >
        <v-icon>
          {{ icons.mdiDotsVertical }}
        </v-icon>
      </v-btn>
    </v-card-title>
    <v-card-subtitle class="mt-n5">
      <span class="font-weight-semibold text--primary me-1">{{ campaign.CampaignID }}</span>
    </v-card-subtitle>
    <v-card-text>
      Campaign Type: <strong>{{ campaign.Type }}</strong>
    </v-card-text>
    <v-card-text>
      <v-row v-if="['video', 'image'].includes(campaign.Type)">
        <v-col cols="8">
          <iframe
            v-if="campaign.MediaURL!='' && showid==campaign.CampaignID"
            :src="campaign.MediaURL"
          ></iframe>
          <v-text-field
            v-if="campaign.MediaURL"
            v-model="campaign.MediaURL"
            label="Creative URL"
            hide-details
          ></v-text-field>
          <v-text-field
            v-if="campaign.TargetURL"
            v-model="campaign.TargetURL"
            label="Target URL"
            hide-details
          ></v-text-field>
        </v-col>
        <v-col
          class="text-right"
          cols="4"
        >
          <v-btn

            v-if="campaign.MediaURL!='' && showid!=campaign.CampaignID"
            x-small
            color="info"
            dark
            @click="showid=campaign.CampaignID"
          >
            Show
          </v-btn>
        </v-col>
      </v-row>
    </v-card-text>
    <v-card-text v-if="['image-gallery'].includes(campaign.Type)">
      <campaign-image-gallery
        :campaign="campaign"
        @reload="$emit('reload')"
      ></campaign-image-gallery>
    </v-card-text>

    <v-card-text>
      <v-chip
        small
        :color="statusColor[campaign.Status]"
        class="font-weight-medium"
      >
        {{ campaign.Status }}
      </v-chip>
    </v-card-text>

    <v-card-text>
      <campaign-rename
        :campaign="campaign"
        class="ma-1"
      ></campaign-rename>
      <campaign-set-media
        v-if="['image', 'video'].includes(campaign.Type)"
        class="ma-1"
        :campaign="campaign"
      ></campaign-set-media>
      <campaign-add-media
        v-if="['image-gallery'].includes(campaign.Type)"
        :campaign="campaign"
        class="ma-1"
        @reload="$emit('reload')"
      ></campaign-add-media>
      <campaign-activate
        v-if="campaign.Status!='active'"
        class="ma-1"
        :campaign="campaign"
      ></campaign-activate>
      <campaign-pause
        v-if="campaign.Status=='active'"
        class="ma-1"
        :campaign="campaign"
      ></campaign-pause>
    </v-card-text>
    <!-- <v-card-text>
      <v-row>
        <v-col
          v-for="data in statisticsData"
          :key="data.title"
          cols="6"
          md="3"
          class="d-flex align-center"
        >
          <v-avatar
            size="44"
            :color="resolveStatisticsIconVariation (data.title).color"
            rounded
            class="elevation-1"
          >
            <v-icon
              dark
              color="white"
              size="30"
            >
              {{ resolveStatisticsIconVariation (data.title).icon }}
            </v-icon>
          </v-avatar>
          <div class="ms-3">
            <p class="text-xs mb-0">
              {{ data.title }}
            </p>
            <h3 class="text-xl font-weight-semibold">
              {{ data.total }}
            </h3>
          </div>
        </v-col>
      </v-row>
    </v-card-text> -->
  </v-card>
</template>

<script>
// eslint-disable-next-line object-curly-newline
import { mdiAccountOutline, mdiCurrencyUsd, mdiTrendingUp, mdiDotsVertical, mdiLabelOutline } from '@mdi/js'
import CampaignRename from './CampaignRename.vue'
import CampaignSetMedia from './CampaignSetMedia.vue'
import CampaignImageGallery from './CampaignImageGallery.vue'
import CampaignAddMedia from './CampaignAddMedia.vue'
import CampaignActivate from './CampaignActivate.vue'
import CampaignPause from './CampaignPause.vue'

export default {
  components: {
    CampaignRename,
    CampaignSetMedia,
    CampaignAddMedia,
    CampaignActivate,
    CampaignPause,
    CampaignImageGallery,
  },
  props: {
    campaign: {
      type: Object,
      default() {
        return {
          CampaignID: '',
          Name: '',
          MediaURL: '',
          Type: '',
        }
      },
    },
  },
  data() {
    return {
      statusColor: {
        active: 'primary',
        pause: 'success',
        Rejected: 'error',
        inactive: 'warning',
        Applied: 'info',
      },
      showid: '',
    }
  },
  setup() {
    const statisticsData = [
      {
        title: 'Sales',
        total: '245k',
      },
      {
        title: 'Customers',
        total: '12.5k',
      },
      {
        title: 'Product',
        total: '1.54k',
      },
      {
        title: 'Revenue',
        total: '$88k',
      },
    ]

    const resolveStatisticsIconVariation = data => {
      if (data === 'Sales') return { icon: mdiTrendingUp, color: 'primary' }
      if (data === 'Customers') return { icon: mdiAccountOutline, color: 'success' }
      if (data === 'Product') return { icon: mdiLabelOutline, color: 'warning' }
      if (data === 'Revenue') return { icon: mdiCurrencyUsd, color: 'info' }

      return { icon: mdiAccountOutline, color: 'success' }
    }

    return {
      statisticsData,
      resolveStatisticsIconVariation,

      // icons
      icons: {
        mdiDotsVertical,
        mdiTrendingUp,
        mdiAccountOutline,
        mdiLabelOutline,
        mdiCurrencyUsd,
      },
    }
  },
}
</script>
