<template>
  <v-dialog
    v-model="isDialogVisible"
    persistent
    max-width="600px"
  >
    <template #activator="{ on, attrs }">
      <v-btn

        x-small
        color="danger"
        dark
        v-bind="attrs"
        v-on="on"
      >
        Set Media
      </v-btn>
    </template>

    <v-card>
      <v-card-title>
        <span class="headline">Set Media {{ campaign.Name }}</span>
      </v-card-title>
      <v-card-text>
        <v-container>
          <v-row>
            <v-col>
              <upload
                @start="mediaURL=''"
                @uploaded="upload"
              ></upload>
            </v-col>
            <v-col
              cols="12"
              sm="12"
              md="12"
            >
              <v-text-field
                v-model="mediaURL"
                label="Creative URL"
                dense
              ></v-text-field>
            </v-col>
            <v-col
              cols="12"
              sm="12"
              md="12"
            >
              <v-text-field
                v-model="campaign.TargetURL"
                label="Target URL"
                dense
              ></v-text-field>
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          color="error"
          outlined
          @click="isDialogVisible = false"
        >
          Close
        </v-btn>
        <v-btn
          color="success"
          @click="setCreative(); isDialogVisible = false"
        >
          Save
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { ref } from '@vue/composition-api'
import api from '../../api'
import Upload from './Upload.vue'

export default {
  components: {
    Upload,
  },
  props: {
    campaign: {
      type: Object,
      default() {
        return {
          CampaignID: '',
          Name: '',
          MediaURL: '',
          TargetURL: '',
        }
      },
    },
  },
  data() {
    return {
      mediaURL: '',
    }
  },
  mounted() {
    this.mediaURL = this.campaign.MediaURL
  },
  methods: {
    upload(media) {
      console.log('upload', media)
      const vm = this
      vm.mediaURL = media.url
    },
    setCreative() {
      const data = {
        TargetURL: this.campaign.TargetURL,
        URL: this.mediaURL,
        CampaignID: this.campaign.CampaignID,
      }

      const vm = this
      api.setCreative(data, () => {
        vm.campaign.MediaURL = vm.mediaURL
        vm.$emit('reload')
      })
    },
  },
  setup() {
    const isDialogVisible = ref(false)

    return { isDialogVisible }
  },
}
</script>
