<template>
  <v-card>
    <v-card-title class="align-start">
      <span class="font-weight-semibold">Campaign Schedule</span>
      <v-spacer></v-spacer>
      <v-btn
        icon
        small
        class="me-n3 mt-n2"
      >
        <v-icon>
          {{ icons.mdiDotsVertical }}
        </v-icon>
      </v-btn>
    </v-card-title>

    <v-card-text v-show="!showSchedule">
      <div v-show="!campaign.IsUseDatePeriod">
        No Schedule
      </div>
      <div v-show="campaign.IsUseDatePeriod">
        {{ campaign.DateStartString }} - {{ campaign.DateEndString }}
      </div>

      <v-btn
        small
        color="secondary"
        @click="showSchedule=true"
      >
        Set Schedule
      </v-btn>
    </v-card-text>
    <v-card-text v-show="showSchedule">
      <v-row>
        <v-col
          cols="12"
          md="12"
        >
          Publish At
        </v-col>
      </v-row>
      <v-row>
        <v-col
          cols="12"
          md="8"
        >
          <v-row justify="center">
            <v-date-picker
              v-model="picker"
              range="range"
            >
            </v-date-picker>
            {{ picker }}
          </v-row>
        </v-col>
        <v-col
          cols="12"
          md="4"
        >
          <v-btn
            v-if="picker.length>1"
            color="primary"
            @click="save"
          >
            Save
          </v-btn>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>

import { ref } from '@vue/composition-api'
import { mdiFilterVariant, mdiDotsVertical } from '@mdi/js'

import api from '../../api'

export default {
  props: {
    campaign: {
      type: Object,
      default() {
        return {
          CampaignID: '',
          Name: '',
          MediaURL: '',
          Tag: ['home', 'chapter'],
          DateStartString: '',
          DateEndString: '',
          IsUseDatePeriod: false,
        }
      },
    },
  },
  data() {
    const pickerx = []
    if (this.campaign.IsUseDatePeriod) {
      const ds = this.campaign.DateStartString
      const de = this.campaign.DateEndString
      pickerx.push(ds)
      pickerx.push(de)
    }

    return {
      picker: pickerx,
      inputTag: '',
      range: true,
      showSchedule: false,
    }
  },
  computed: {
    campaignView() {
      return ref(this.campaign)
    },
  },
  methods: {
    save() {
      const vm = this
      const callback = function () {
        vm.$emit('reload')
        vm.showSchedule = false
      }
      api.changeSchedule(this.campaign.CampaignID, this.picker[0], this.picker[1], callback)
    },
  },
  setup() {
    const chips = ref(['Programming', 'Playing video games', 'Sleeping'])
    const items = ref(['x'])

    return {
      chips,
      items,
      icons: {
        mdiFilterVariant,
        mdiDotsVertical,
      },
    }
  },
}
</script>
