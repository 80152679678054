<template>
  <v-card>
    <v-card-title class="align-start">
      <span class="font-weight-semibold">Tags</span>
      <v-spacer></v-spacer>
      <v-btn
        icon
        small
        class="me-n3 mt-n2"
      >
        <v-icon>
          {{ icons.mdiDotsVertical }}
        </v-icon>
      </v-btn>
    </v-card-title>
    <v-card-text>
      <v-row v-if="campaign.Tag.length>0">
        <v-col
          cols="12"
          md="12"
        >
          <v-chip
            v-for="item in campaign.Tag"
            :key="item"
            class="mr-2 mb-2"
            close
            @click:close="remove(item)"
          >
            <strong>{{ item }}</strong>&nbsp;
          </v-chip>
        </v-col>
      </v-row>
      <v-row>
        <v-col
          cols="12"
          md="8"
        >
          <v-text-field
            v-model="inputTag"
            outlined
            dense
            label="Add Tag"
            hide-details
            @keyup.enter.native="onEnter()"
          ></v-text-field>
        </v-col>
        <v-col
          cols="12"
          md="4"
        >
          <v-btn
            color="primary"
            @click="onEnter"
          >
            Add Tag
          </v-btn>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
import { ref } from '@vue/composition-api'
import { mdiFilterVariant, mdiDotsVertical } from '@mdi/js'
import api from '../../api'

export default {
  props: {
    campaign: {
      type: Object,
      default() {
        return {
          CampaignID: '',
          Name: '',
          MediaURL: '',
          Tag: ['home', 'chapter'],
        }
      },
    },
  },
  data() {
    return {
      inputTag: '',
    }
  },
  computed: {
    campaignView() {
      return ref(this.campaign)
    },
  },
  watch: {
    chips(val) {
      console.log(val)
    },
  },
  methods: {
    onEnter() {
      const vm = this
      function cb() {
        vm.campaign.Tag.push(vm.inputTag)
        vm.inputTag = ''
      }
      api.addTag(vm.campaign.CampaignID, vm.inputTag, cb)
    },
    remove(item) {
      const vm = this
      function cb() {
        const newTag = []
        vm.campaign.Tag.forEach(tag => {
          if (item !== tag) {
            newTag.push(tag)
          }
        })
        vm.campaign.Tag = newTag
      }
      api.removeTag(vm.campaign.CampaignID, item, cb)
    },
  },
  setup() {
    const chips = ref(['Programming', 'Playing video games', 'Sleeping'])
    const items = ref(['x'])

    // const remove = item => {
    //   chip.value.splice(chips.value.indexOf(item), 1)
    //   chips.value = [...chips.value]
    // }

    return {
      chips,
      items,
      icons: {
        mdiFilterVariant,
        mdiDotsVertical,
      },
    }
  },
}
</script>
