<template>
  <v-dialog
    v-model="isDialogVisible"
    persistent
    max-width="600px"
  >
    <template #activator="{ on, attrs }">
      <v-btn
        color="primary"
        dark
        dense
        v-bind="attrs"

        x-small
        v-on="on"
      >
        Change Reservation
      </v-btn>
    </template>

    <v-card>
      <v-card-title>
        <span class="headline">Change Reservation</span>
      </v-card-title>
      <v-card-text>
        <v-container>
          <v-row>
            <v-col
              cols="12"
              sm="12"
              md="12"
            >
              Period: {{ period }}
            </v-col>
          </v-row>
          <v-row>
            <v-col
              cols="12"
              sm="12"
              md="12"
            >
              <v-text-field
                v-model="impression"
                label="Daily Impression"
                dense
                type="number"
              ></v-text-field>
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          color="error"
          outlined
          @click="isDialogVisible = false"
        >
          Close
        </v-btn>
        <v-btn
          color="success"
          @click="isDialogVisible = false; changeReservation()"
        >
          Save
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { ref } from '@vue/composition-api'
import api from '../../api'

export default {
  props: {
    impression: {
      type: Number,
      default: 0,
    },
    period: {
      type: String,
      default: '',
    },
    campaign: {
      type: Object,
      default() {
        return {
          CampaignID: '',
          Name: '',
          MediaURL: '',
          Tag: ['home', 'chapter'],
        }
      },
    },
  },
  data() {
    return {
      impressionValue: 0,
    }
  },
  mounted() {
    this.impressionValue = this.impression
    console.log(this.impression)
  },
  methods: {
    changeReservation() {
      const vm = this
      const impression = parseInt(vm.impression, 10)
      api.changeReservation(vm.campaign.CampaignID, vm.period, impression, () => {
        vm.$emit('reload')
      })
    },
  },
  setup() {
    const isDialogVisible = ref(false)

    return { isDialogVisible }
  },
}
</script>
