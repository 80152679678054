<template>
  <v-row>
    <v-col
      cols="12"
      sm="6"
    >
      <v-file-input
        v-model="imageUpload"
        show-size
        label="File input"
        hide-details
        @change="percent=0"
      ></v-file-input>
    </v-col>
    <v-col
      cols="12"
      sm="6"
    >
      <v-btn
        color="info"
        @click="upload()"
      >
        Submit
      </v-btn>
    </v-col>
    <v-col
      cols="12"
      sm="6"
    >
      <v-progress-linear
        v-model="percent"
        color="secondary"
        height="25"
      >
        <template v-slot:default="{ value }">
          <strong>{{ value }}%</strong>
        </template>
      </v-progress-linear>
    </v-col>
  </v-row>
</template>

<script>
import { ref } from '@vue/composition-api'
import api from '../../api'

export default {
  data() {
    return {
      name: '',
      imageUpload: null,
      percent: 0,
    }
  },
  methods: {
    createCampaign() {
      const vm = this
      const data = {
        Name: vm.name,
        Type: vm.type,
      }

      function reload() {
        window.location.reload()
      }

      api.createCampaign(data, reload)
    },
    upload() {
      const vm = this
      vm.$emit('start')

      const data = {
        file: vm.imageUpload,
      }

      console.log(JSON.stringify(data.file))

      const cb = function (media) {
        vm.$emit('uploaded', media)
      }

      const progress = function (percent) {
        console.log(`Upload ${percent} percent`)
        vm.percent = percent
      }
      api.upload(data, cb, progress)
    },
  },
  setup() {
    const isDialogVisible = ref(false)

    return { isDialogVisible }
  },
}
</script>
