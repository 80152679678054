<template>
  <v-btn
    color="info"
    dark
    x-small

    @click="pause"
  >
    Pause
  </v-btn>
</template>

<script>
import { ref } from '@vue/composition-api'
import api from '../../api'

export default {
  props: {
    campaign: {
      type: Object,
      default() {
        return {
          CampaignID: '',
          Name: '',
          MediaURL: '',
        }
      },
    },
  },
  methods: {
    pause() {
      const vm = this
      const data = {
        CampaignID: this.campaign.CampaignID,
      }

      function after() {
        vm.campaign.Status = 'pause'
      }

      api.pauseCampaign(data, after)
    },
  },
  setup() {
    const isDialogVisible = ref(false)

    return { isDialogVisible }
  },
}
</script>
