<template>
  <v-row>
    <v-col
      cols="12"
      md="4"
    >
      <campaign-detail-property
        :campaign="campaign"
        @reload="reload"
      ></campaign-detail-property>
    </v-col>
    <v-col
      cols="12"
      md="4"
    >
      <campaign-detail-tag :campaign="campaign"></campaign-detail-tag>
      <campaign-schedule :campaign="campaign" @reload="reload"></campaign-schedule>
    </v-col>
    <v-col
      cols="12"
      md="4"
    >
      <campaign-reservation
        :campaign="campaign"
        @reload="reload"
      ></campaign-reservation>

      <campaign-summary
        :campaign="campaign"
        @reload="reload"
      ></campaign-summary>
    </v-col>
    <v-col
      cols="12"
      md="12"
    >
      <v-card>
        <v-btn
          :href="exportCSV"
          color="info"
          dark
          class="ma-3"
        >
          <v-icon>mdi-square-edit-outline</v-icon>
          <span>Export CSV</span>
        </v-btn>
        <v-data-table
          :items-per-page="300"
          :headers="headers"
          :items="reports"
          item-key="full_name"
          class="table-rounded"
          hide-default-footer
          disable-sort
        ></v-data-table>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
// eslint-disable-next-line object-curly-newline
import { mdiPoll, mdiLabelVariantOutline, mdiCurrencyUsd, mdiHelpCircleOutline } from '@mdi/js'

// demos
import CampaignDetailProperty from './CampaignDetailProperty.vue'
import CampaignDetailTag from './CampaignDetailTag.vue'
import CampaignReservation from './CampaignReservation.vue'
import CampaignSummary from './CampaignSummary.vue'
import CampaignSchedule from './CampaignSchedule.vue'
import api from '../../api'

export default {
  components: {
    CampaignDetailProperty,
    CampaignDetailTag,
    CampaignReservation,
    CampaignSummary,
    CampaignSchedule,
  },
  data() {
    return {
      exportCSV: '',
      showReport: false,
      headers: [
        { text: 'Campaign ID', value: 'CampaignID' },
        { text: 'Date', value: 'DateText' },
        { text: 'Impression', value: 'TotalRequest' },
        { text: 'Click', value: 'TotalClick' },
      ],
      reports: [],
      campaign: {
        CampaignID: '',
        Name: '',
        MediaURL: '',
        Tag: [],
      },
    }
  },
  mounted() {
    this.reload()
    const campaignID = new URL(window.location.href).searchParams.get('campaign_id')
    const vm = this
    function setCampaign(data) {
      vm.reports = data
      console.log(data)
    }

    vm.exportCSV = `${api.baseUrl()}export-csv?CampaignID=${campaignID}`
    api.reportCampaign(campaignID, setCampaign)
  },
  methods: {

  },
  setup() {
    const totalProfit = {
      statTitle: 'Total Profit',
      icon: mdiPoll,
      color: 'success',
      subtitle: 'Weekly Project',
      statistics: '$25.6k',
      change: '+42%',
    }

    const totalSales = {
      statTitle: 'Refunds',
      icon: mdiCurrencyUsd,
      color: 'secondary',
      subtitle: 'Past Month',
      statistics: '$78',
      change: '-15%',
    }

    // vertical card options
    const newProject = {
      statTitle: 'New Project',
      icon: mdiLabelVariantOutline,
      color: 'primary',
      subtitle: 'Yearly Project',
      statistics: '862',
      change: '-18%',
    }

    const salesQueries = {
      statTitle: 'Sales Quries',
      icon: mdiHelpCircleOutline,
      color: 'warning',
      subtitle: 'Last week',
      statistics: '15',
      change: '-18%',
    }

    function reload() {
      const campaignID = new URL(window.location.href).searchParams.get('campaign_id')
      const vm = this
      function setCampaign(campaign) {
        vm.campaign = campaign
      }

      api.getCampaign(campaignID, setCampaign)
    }

    return {
      totalProfit,
      totalSales,
      newProject,
      salesQueries,
      reload,
    }
  },
}
</script>
