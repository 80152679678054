<template>
  <v-card>
    <v-card-title class="align-start">
      <span>Campaign Summary</span>

      <v-spacer></v-spacer>

      <v-btn
        icon
        small
        class="me-n3 mt-n2"
      >
        <v-icon>
          {{ icons.mdiDotsVertical }}
        </v-icon>
      </v-btn>
    </v-card-title>

    <v-card-text>
      <v-list class="pb-0">
        <v-list-item
          :class="`d-flex align-center px-0`"
        >
          <v-avatar
            :color="'success'"
            size="40"
            :class="`success white--text font-weight-medium me-3`"
          >
            <span class="text-base">DI</span>
          </v-avatar>
          <div class="d-flex align-center flex-grow-1 flex-wrap">
            <div class="me-2">
              <div class="font-weight-semibold">
                <span class="text--primary text-base me-1">{{ campaign.DailyImpressionSummary }}</span>

                <v-icon
                  size="20"
                  :color="'success'"
                >
                </v-icon>

                <span :class="`text-xs success--text`"></span>
              </div>

              <v-list-item-subtitle class="text-xs">
                Daily Impression
              </v-list-item-subtitle>
            </div>

            <v-spacer></v-spacer>

            <div>
            </div>
          </div>
        </v-list-item>
        <v-list-item
          :class="`d-flex align-center px-0`"
        >
          <v-avatar
            :color="'success'"
            size="40"
            :class="`success white--text font-weight-medium me-3`"
          >
            <span class="text-base">DC</span>
          </v-avatar>
          <div class="d-flex align-center flex-grow-1 flex-wrap">
            <div class="me-2">
              <div class="font-weight-semibold">
                <span class="text--primary text-base me-1">{{ campaign.DailyClickSummary }}</span>

                <v-icon
                  size="20"
                  :color="'success'"
                >
                </v-icon>

                <span :class="`text-xs success--text`"></span>
              </div>

              <v-list-item-subtitle class="text-xs">
                Daily Click
              </v-list-item-subtitle>
            </div>

            <v-spacer></v-spacer>

            <div>
            </div>
          </div>
        </v-list-item>
      </v-list>
    </v-card-text>
  </v-card>
</template>

<script>
import { mdiDotsVertical, mdiChevronUp, mdiChevronDown } from '@mdi/js'

export default {
  props: {
    campaign: {
      type: Object,
      default() {
        return {
          CampaignID: '',
          Name: '',
          MediaURL: '',
          Tag: ['home', 'chapter'],
        }
      },
    },
  },
  setup() {
    const salesByCountries = [
      {
        abbr: 'DI',
        amount: '130k',
        country: 'Daily Impression',
        change: '+25.8%',
        sales: '894k',
        color: 'success',
      },
      {
        abbr: 'UK',
        amount: '$2,415k',
        country: 'United kingdom',
        change: '-6.2%',
        sales: '645k',
        color: 'error',
      },
      {
        abbr: 'IN',
        amount: '$865k',
        country: 'India',
        change: '+12.4%',
        sales: '148k',
        color: 'warning',
      },
      {
        abbr: 'JA',
        amount: '$745k',
        country: 'Japan',
        change: '-11.9%',
        sales: '86k',
        color: 'secondary',
      },
      {
        abbr: 'KO',
        amount: '$45k',
        country: 'Korea',
        change: '+16.2%',
        sales: '42k',
        color: 'error',
      },
    ]

    return {
      salesByCountries,
      icons: {
        mdiDotsVertical,
        mdiChevronUp,
        mdiChevronDown,
      },
    }
  },
}
</script>
