<template>
  <div>
    <v-row
      v-for="(media,idx) in campaign.MediaGallery"
      :key="'med'+idx"
    >
      <v-col cols="8">
        <v-text-field
          :value="media.URL"
          label="Creative URL"
          hide-details
        ></v-text-field>
        <v-text-field
          :value="media.TargetURL"
          label="Target URL"
          hide-details
        ></v-text-field>
      </v-col>
      <v-col
        class="text-right"
        cols="4"
      >
        <v-btn

          x-small
          color="error"
          @click="removeCreative(campaign, media.CreativeID)"
        >
          Remove
        </v-btn>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import api from '../../api'

export default {
  props: {
    campaign: {
      type: Object,
      default() {
        return {
          CampaignID: '',
          Name: '',
          MediaURL: '',
        }
      },
    },
  },
  data() {
    return {
      mediaURL: '',
    }
  },
  methods: {
    removeCreative(campaign, creativeID) {
      const data = {
        CreativeID: creativeID,
        CampaignID: campaign.CampaignID,
      }

      api.removeCreative(data, () => {
        this.$emit('reload')

        return true
      })
    },
  },
}
</script>
