<template>
  <v-dialog
    v-model="isDialogVisible"
    persistent
    max-width="600px"
  >
    <template #activator="{ on, attrs }">
      <v-btn

        x-small
        color="secondary"
        dark
        v-bind="attrs"
        v-on="on"
      >
        Add Media
      </v-btn>
    </template>

    <v-card>
      <v-card-title>
        <span class="headline">Add Media {{ campaign.Name }}</span>
      </v-card-title>
      <v-card-text>
        <v-container>
          <!-- <v-row>
            <v-col
              cols="12"
              sm="6"
            >
              <v-file-input
                v-model="imageUpload"
                show-size
                label="File input"
                hide-details
              ></v-file-input>
            </v-col>
            <v-col
              cols="12"
              sm="6"
            >
              <v-btn
                color="info"
                @click="upload()"
              >
                Submit
              </v-btn>
            </v-col>
          </v-row> -->
          <upload
            @start="mediaURL=''"
            @uploaded="upload"
          ></upload>
          <v-row>
            <v-col
              cols="12"
              sm="12"
              md="12"
            >
              <v-text-field
                v-model="mediaURL"
                label="Creative URL"
                dense
              ></v-text-field>
            </v-col>

            <v-col
              cols="12"
              sm="12"
              md="12"
            >
              <v-text-field
                v-model="targetURL"
                label="Target URL"
                dense
              ></v-text-field>
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          color="error"
          outlined
          @click="isDialogVisible = false"
        >
          Close
        </v-btn>
        <v-btn
          color="success"
          @click="setCreative(); isDialogVisible = false"
        >
          Save
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { ref } from '@vue/composition-api'
import api from '../../api'
import Upload from './Upload.vue'

export default {
  components: {
    Upload,
  },
  props: {
    campaign: {
      type: Object,
      default() {
        return {
          CampaignID: '',
          Name: '',
          MediaURL: '',
        }
      },
    },
  },
  data() {
    return {
      mediaURL: '',
      targetURL: '',
      imageUpload: null,
    }
  },
  methods: {
    setCreative() {
      const data = {
        URL: this.mediaURL,
        TargetURL: this.targetURL,
        CampaignID: this.campaign.CampaignID,
      }

      const vm = this
      api.addCreative(data, () => {
        vm.$emit('reload')

        return true
      })
    },
    getBase64(file) {
      const reader = new FileReader()
      reader.readAsDataURL(file)
      reader.onload = function () {
        console.log(reader.result)
      }
      reader.onerror = function (error) {
        console.log('Error: ', error)
      }
    },
    upload(media) {
      const vm = this
      vm.mediaURL = media.url
    },
  },
  setup() {
    const isDialogVisible = ref(false)

    return { isDialogVisible }
  },
}
</script>
